.auth-layout {
  height: 100vh;

  background: radial-gradient(ellipse at top, #1c1c1c, transparent),
    radial-gradient(ellipse at bottom, #1d1d1d, transparent);
  background-color: #222;
  background-position: center, center;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    // background-color: #142550;
    border-radius: 1rem;
    padding: 2rem;
    max-width: 560px;
    width: 90vw;
  }

  .help-button {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 1000;
    border-radius: 100%;
    font-size: 36px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-image: url('../../assets/imgs/hybridley-support.png');
    background-size: cover;
    background-position: center;

    &:hover {
      background-image: url('../../assets/imgs/hybridley-support-on-hover.png');
    }
  }
}

@primary-color: #1DA57A;