/* @import '~antd/dist/antd.dark.less'; */

body {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.w-full {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.sketch-picker {
  color: black;
  font-size: 10;
}

th,
td {
  padding: 0.5rem !important;
}

.p-1 {
  padding: 1rem;
}

@primary-color: #1DA57A;