.portal-layout.ant-layout {
  height: 100vh;
  overflow: auto;

  .portal-header {
    background-color: #1f1f1f;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .brand-logo {
      height: 40px;
    }

    .ant-dropdown-trigger {
      color: white;
    }

    .event-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 24px;
    }
  }

  .portal-content {
    // background-color: #16181D;
  }

  .help-button {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 1000;
    border-radius: 100%;
    font-size: 36px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-image: url('../../assets/imgs/hybridley-support.png');
    background-size: cover;
    background-position: center;

    &:hover {
      background-image: url('../../assets/imgs/hybridley-support-on-hover.png');
    }
  }
}

@primary-color: #1DA57A;